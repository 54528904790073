import React from 'react';
import PropTypes from 'prop-types';

const KEY_SPACE = 32;


const ClickableDiv = (props) => {
  const { onClick, children } = props;
  if (onClick) {
    return (
      <div
        {...props}
        onClick={onClick}
        onKeyPress={(e) => { if (e.which === KEY_SPACE) { onClick(e); } }}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
    );
  }

  return <div {...props}>{children}</div>;
};

ClickableDiv.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
};

ClickableDiv.defaultProps = {
  onClick: null,
  children: null,
};


export default ClickableDiv;
