import { CookieStorage } from 'cookie-storage';
import moment from 'moment';

export default new CookieStorage({
  path: '/',
  domain: process.env.REACT_APP_COOKIE_DOMAIN,
  expires: moment().add(30, 'd').toDate(),
  secure: process.env.REACT_APP_ALLOW_INSECURE_COOKIE !== 'true',
  sameSite: 'Lax', // Can be 'Strict' or 'Lax'.
});
