import React from "react";
import PropTypes from "prop-types";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { getFileTypeIconProps } from "@uifabric/file-type-icons";
import { extname } from "path";

import serverLink from "../utils/ServerLink";

const Thumbnail = ({ file, link }) => {
  const extension = extname(file);
  const img = <Icon {...getFileTypeIconProps({ extension, size: 32 })} />;

  if (link) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={serverLink(file)}>
        {img}
      </a>
    );
  }

  return img;
};

Thumbnail.propTypes = {
  file: PropTypes.string.isRequired,
  link: PropTypes.bool
};

Thumbnail.defaultProps = {
  link: false
};

export default Thumbnail;
