import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./StarRating.module.scss";

const starLoop = ({ stars, max }) => {
  const rating = [];
  for (let counter = 1; counter <= max; counter += 1) {
    rating.push(
      <div
        key={counter}
        className={classnames({
          [styles.Star]: true,
          [styles.Filled]: counter <= stars
        })}
      />
    );
  }

  return rating;
};

const StarRating = ({ stars, editable, max }) => (
  <div
    className={classnames({
      [styles.Container]: true,
      [styles.Editable]: editable
    })}
  >
    {starLoop({ stars, max })}
  </div>
);

StarRating.propTypes = {
  stars: PropTypes.number,
  editable: PropTypes.bool,
  max: PropTypes.number
};

StarRating.defaultProps = {
  editable: false,
  max: 5,
  stars: 0
};
export default StarRating;
